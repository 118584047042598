import React,{useState,useEffect} from 'react'
import './style.css'
import BackgroundImage from '../../component/BackgroundImage'
import Logout from '../../assets/logout.png'
// import NotificationIcon from '../../assets/notification2.svg'
import WODOLogo from '../../assets/WODO.png'
import {Link} from 'react-router-dom'


import { useNavigate,useParams } from 'react-router-dom';
import axios from 'axios'
import Cookies from 'js-cookie';

import BaseURL from '../../baseURL'
import Carousel from '../../component/carousel/Carousel'
import Tables from './Tables'
import formatDate from '../../component/formatDate'
import Notification from '../../component/Notification/Notification'


function Index() {
    const [phases, setPhases] = useState({})
    const navigate = useNavigate();
    const { id } = useParams();
    const cookieExists = Cookies.get('login');
    const clientName = Cookies.get('clientName');

    useEffect(()=>{
        async function getPhases(){
            if(cookieExists===id){
                await axios.post(BaseURL+'/client_data.php',{name:id,test:1})
                .then((res)=>{   
                    // const phaseKeys = Object.keys(res.data.phase);
                    setPhases(res.data.phase);
                    // console.log(res.data.phase);
                })
                .catch((err)=>{console.log(err)})
            }else{
                navigate('/');
            }
        }
        getPhases();
    },[id,navigate,cookieExists]);






    function Header(){
        return(
            <>
            {/* bg-body-tertiary */}
                <nav className="navbar" style={{paddingTop:20,paddingBottom:40}}>
                    <div className="container">
                        <Link to='./' className="navbar-brand">
                            <img src={WODOLogo} style={{width:'130px'}} alt="" />
                        </Link>

                        <div className="d-flex" style={{gap:'20px'}} >
                            <div className='dropdown' style={{position:'relative'}}>
                                <span className='notificationBadge verticle_center' style={{display:'none'}} id='notificationBadgeId'></span>
                                <i className='fas fa-bell' data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" style={{fontSize:25,cursor:'pointer'}}></i>
                                {/* <img  src={NotificationIcon} alt="" style={{cursor:'pointer'}}/> */}
                                <Notification/>
                            </div>
                            <img src={Logout}  alt="" onClick={()=>{navigate('/logout')}} style={{cursor:'pointer',width:25,height:25}}/>
                        </div>
                    </div>
                </nav>
            </>
        );
    }

    function Greeting(){
        return(
            <div className='container MainPageBody py-2'>
                {/* <div style={{fontSize:'1.8rem',fontWeight:900}}>Hello</div>
                <div style={{fontSize:'2.2rem',fontWeight:100}}>{clientName}</div> */}
                <div className='sectionTitle'>Hello</div>
                <div className='sectionTitle-2'>{clientName}</div>
            </div>
        );
    }


    function Progress(){

        const [firstDate, setFirstDate] = useState('DD/MM/YYYY')
        const [lastDate, setLastDate] = useState('DD/MM/YYYY')
        const [progress, setProgress] = useState(0);


        useEffect(()=>{
            async function getClientOnBoard(){
                if(cookieExists===id){
                    await axios.post(BaseURL+'/client_details.php',{name:id,test:1})
                    .then((res)=>{   
                        setFirstDate(res.data.data.start_date);
                        setLastDate(res.data.data.launch_date)
                        // console.log(res.data.data.lunch_date);
                    })
                    .catch((err)=>{console.log(err)})
                }else{
                    navigate('/');
                }
            }
            getClientOnBoard();
        },[firstDate,lastDate]);


        // useEffect(() => {
        //     const allTasks = [];
            
        //     for (const phase in phases) {
        //         allTasks.push(...phases[phase].task);
        //     }

        //     const maxLen=allTasks.length;

        //     try {
        //         // setFirstDate(allTasks[0].deadline);
        //         // setLastDate(allTasks[maxLen-1].deadline);
        //         // console.log("Start",allTasks[0].deadline);
        //         // console.log("Launch",allTasks[maxLen-1].deadline);

        //     } catch (error) {
                
        //     }

        // }, [])
        




        const getTotalTasks = (data) => {
            return Object.values(data).reduce((total, phase) => total + phase.task.length, 0);
        };

        const getCompletedTasks = (data) => {
            return Object.values(data).reduce((total, phase) => {
                const completedTasks = phase.task.filter(task => task.status === 'completed').length;
                return total + completedTasks;
            }, 0);
        };

        const currentProgress = Math.round(getCompletedTasks(phases) / getTotalTasks(phases) * 100);

        useEffect(() => {
            setTimeout(function(){
                setProgress(currentProgress);
            },100);
        }, [currentProgress]);

        return(
            <>
                <div className="container py-3 mt-4">
                    <span className='sectionTitle'>Project Progress</span>
                    <div className="row py-2">
                        <div className="col dateStyle fs16">
                            Start: {firstDate!==''?formatDate(firstDate):<span className="badge bg-warning" style={{cursor:'default'}} title='To be decided'>TBD</span>} 
                        </div>
                        <div className="col dateStyle fs16" style={{textAlign:'right'}}>
                            Launch: {lastDate!==''?formatDate(lastDate):<span className="badge bg-warning" style={{cursor:'default'}} title='To be decided'>TBD</span>} 
                        </div>
                    </div>
                    <div className="progress" role="progressbar" style={{height:'25px',borderRadius:'50px'}} aria-label="Success" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
                        <div className="progress-bar" style={{width:`${progress}%`,backgroundColor: "#3ec200",color:'#fff',fontWeight:700,transition: 'width 1s ease-in-out' }}>{progress}%</div>
                    </div>
                </div>
            </>
        );


    }






  return (
    <>
        <Header/>
        <Greeting/>
        <Carousel phases={phases}/>
        <Progress/>
        <Tables/>
        <BackgroundImage/>
    </>
  )
}

export default Index