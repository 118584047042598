import React,{ useRef,useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import "./carouselCss.css";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import formatDate from '../formatDate';


function Carousel(props) {

    const sliderRef = useRef(null);
    const [prevDisabled, setPrevDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(false);
  

    const phases=props.phases;




    function Phases(props){
        const { task } = props;
        const completedTasks = task.filter(task => task.status === 'completed').length;
        const completedProgess=Math.round(completedTasks/task.length*100);

        return(
            <>
                <div className='phaseBlockParent'>
                    <div className='phaseBlock'>
                        <div className='phaseBlockHeader'>
                            <div className="row">
                                <div className="col fs16 verticle_center_phases ">
                                    {props.phaseTitle}: <strong>{props.title}</strong>
                                </div>
                                <div className="col" style={{textAlign:'right',position:'relative',display:'flex',flexDirection:'row-reverse'}}>
                                    {/* {completedProgess+'%'} */}
                                    {/* <CircularProgressbar value={completedProgess} text={`${completedProgess}%`} />; */}
                                    <div style={{ width: 40, height: 40}}>
                                        <CircularProgressbar value={completedProgess} text={`${completedProgess}%`}
                                          styles={{
                                            // Customize the root svg element
                                            root: {},
                                            // Customize the path, i.e. the "completed progress"
                                            path: {
                                              // Path color
                                              stroke: '#000',
                                            //   stroke: '#97FC8E',
                                              strokeWidth: 8,
                                              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                            //   strokeLinecap: 'butt',
                                              // Customize transition animation
                                              transition: 'stroke-dashoffset 0.5s ease 0s',
                                              // Rotate the path
                                            //   transform: 'rotate(0.25turn)',
                                              transformOrigin: 'center center',
                                            },
                                            // Customize the circle behind the path, i.e. the "total progress"
                                            trail: {
                                              // Trail color
                                            //   stroke: '#d6d6d6',
                                              stroke: '##0000001f',
                                              strokeWidth: 10,

                                              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                            //   strokeLinecap: 'butt',
                                              // Rotate the trail
                                              transform: 'rotate(0.25turn)',
                                              transformOrigin: 'center center',
                                            },
                                            // Customize the text
                                            text: {
                                              // Text color
                                              fill: '#000',
                                              fontWeight:'600',
                                              // Text size
                                              fontSize: '25px',
                                            },
                                            // Customize background - only used when the `background` prop is true
                                            background: {
                                              fill: '#3e98c7',
                                            },
                                          }}
                                        />
                                        {/* stroke: '#97FC8E', */}
                                    </div>
                                </div>
                            </div>
                        
                       
                        </div>
                        <div className="ParentPhaseBlockBody">
                            <div className="phaseBlockBody">
                                {/* <p>An indepth discovery of the client and their services to forumulate a strong strategy that set’s the foundation.</p> */}
                                <div className='py-2 ps-2 fs14' style={{paddingLeft:14}}>{props.description}</div>
                                <table className='table fs14'>
                                    <thead>
                                        <tr className='tr_header_carousel'>
                                            <th colSpan={2}>Tasks</th>
                                            <th style={{textAlign:'center'}}>Due Date</th>
                                            {/* <th style={{textAlign:'center'}}>Status</th> */}
                                        </tr>
                                    </thead>
                                    {props.task && props.task.map((task,index)=>(
                                        // <tbody>
                                            <tr className='data_tr' key={index} title={task.status}>
                                                <td className='data_td0' style={{textAlign:'center',width:'10px'}}>
                                                    {task.status === 'completed' ? 
                                                        (
                                                            <div className='status-badge' title='Completed' style={{backgroundColor:'#3EC200'}}></div>
                                                            // <span className='badge text-bg-success'>Completed</span>
                                                        ) : task.status === 'in-progress' ? (
                                                            <div className='status-badge' title='In Progress' style={{backgroundColor:'#ffc704'}}></div>
                                                            // <span className='badge text-bg-warning'>In Progress</span>
                                                        ) : (
                                                            <div className='status-badge' title='Pending' style={{backgroundColor:'red'}}></div>
                                                            // <span className='badge text-bg-danger'>Pending</span>
                                                        )
                                                    }
                                                    {/* {task.status==='completed'?<span className='badge text-bg-success'>Completed</span>:<span className='badge text-bg-danger'>Pending</span>} */}
                                                </td>
                                                <td>
                                                    {task.task}
                                                </td>
                                                <td style={{textAlign:'center'}}>
                                                    {task.deadline!==''?formatDate(task.deadline):<span className='badge bg-warning text-white' style={{cursor:'default'}} title="To be decided">TBD</span>}
                                                </td>

                                            </tr>
                                        // </tbody>
                                    ))}
                                </table>
                                <div className="table-container-phases" style={{display:'none'}}>
                                    <table className='table-phases'>
                                        <tr className='tr_header_carousel'>
                                            <th className='th-phases' colSpan={2}>Tasks</th>
                                            <th className='th-phases'>Due Date</th>
                                        </tr>
                                        {props.task && props.task.map((task,index)=>(
                                            <tr className='data_tr' key={index} title={task.status}>
                                                <td className='td-phases' style={{textAlign:'center',width:'10px'}}>
                                                    {task.status === 'completed' ? 
                                                        (
                                                            <div className='status-badge' title='Completed' style={{backgroundColor:'#60e160'}}></div>
                                                        ) : task.status === 'in-progress' ? (
                                                            <div className='status-badge' title='In Progress' style={{backgroundColor:'orange'}}></div>
                                                        ) : (
                                                            <div className='status-badge' title='Pending' style={{backgroundColor:'red'}}></div>
                                                        )
                                                    }
                                                </td>
                                                <td className='td-phases'>
                                                    {task.task}
                                                </td>
                                                <td className='td-phases' style={{textAlign:'center'}}>
                                                    {formatDate(task.deadline)}
                                                </td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }


    // function PhasesV2(props){
    //     const { task } = props;
    //     const completedTasks = task.filter(task => task.status === 'completed').length;
    //     const completedProgess=Math.round(completedTasks/task.length*100);

    //     return(
    //         <>
    //             <div className='phaseBlockV2'>
    //                 <div className='phaseBlockHeader'>
    //                     <div className="row">
    //                         <div className="col fs16 verticle_center_phases ">
    //                             {props.phaseTitle}: <strong>{props.title}</strong>
    //                         </div>
    //                         <div className="col" style={{textAlign:'right',position:'relative',display:'flex',flexDirection:'row-reverse'}}>
    //                             {/* {completedProgess+'%'} */}
    //                             {/* <CircularProgressbar value={completedProgess} text={`${completedProgess}%`} />; */}
    //                             <div style={{ width: 40, height: 40}}>
    //                                 <CircularProgressbar value={completedProgess} text={`${completedProgess}%`}
    //                                     styles={{
    //                                     // Customize the root svg element
    //                                     root: {},
    //                                     // Customize the path, i.e. the "completed progress"
    //                                     path: {
    //                                         // Path color
    //                                         stroke: '#000',
    //                                     //   stroke: '#97FC8E',
    //                                         strokeWidth: 10,
    //                                         // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    //                                     //   strokeLinecap: 'butt',
    //                                         // Customize transition animation
    //                                         transition: 'stroke-dashoffset 0.5s ease 0s',
    //                                         // Rotate the path
    //                                     //   transform: 'rotate(0.25turn)',
    //                                         transformOrigin: 'center center',
    //                                     },
    //                                     // Customize the circle behind the path, i.e. the "total progress"
    //                                     trail: {
    //                                         // Trail color
    //                                     //   stroke: '#d6d6d6',
    //                                         stroke: '##0000001f',
    //                                         strokeWidth: 10,

    //                                         // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    //                                     //   strokeLinecap: 'butt',
    //                                         // Rotate the trail
    //                                         transform: 'rotate(0.25turn)',
    //                                         transformOrigin: 'center center',
    //                                     },
    //                                     // Customize the text
    //                                     text: {
    //                                         // Text color
    //                                         fill: '#000',
    //                                         fontWeight:'600',
    //                                         // Text size
    //                                         fontSize: '25px',
    //                                     },
    //                                     // Customize background - only used when the `background` prop is true
    //                                     background: {
    //                                         fill: '#3e98c7',
    //                                     },
    //                                     }}
    //                                 />
    //                                 {/* stroke: '#97FC8E', */}
    //                             </div>
    //                         </div>
    //                     </div>
                    
                    
    //                 </div>
    //                 <div className="ParentPhaseBlockBody">
    //                     <div className="phaseBlockBody">
    //                         {/* <p>An indepth discovery of the client and their services to forumulate a strong strategy that set’s the foundation.</p> */}
    //                         <div className='py-2 ps-2 fs14' style={{paddingLeft:14}}>{props.description}</div>
    //                         <table className='table fs14'>
    //                             {/* <thead> */}
    //                                 <tr className='tr_header_carousel'>
    //                                     <th colSpan={2}>Tasks</th>
    //                                     <th style={{textAlign:'center'}}>Due Date</th>
    //                                     {/* <th style={{textAlign:'center'}}>Status</th> */}
    //                                 </tr>
    //                             {/* </thead> */}
    //                             {props.task && props.task.map((task,index)=>(
    //                                 // <tbody>
    //                                     <tr className='data_tr' key={index} title={task.status}>
    //                                         <td className='data_td0' style={{textAlign:'center',width:'10px'}}>
    //                                             {task.status === 'completed' ? 
    //                                                 (
    //                                                     <div className='status-badge' title='Completed' style={{backgroundColor:'#3EC200'}}></div>
    //                                                     // <span className='badge text-bg-success'>Completed</span>
    //                                                 ) : task.status === 'in-progress' ? (
    //                                                     <div className='status-badge' title='In Progress' style={{backgroundColor:'#ffc704'}}></div>
    //                                                     // <span className='badge text-bg-warning'>In Progress</span>
    //                                                 ) : (
    //                                                     <div className='status-badge' title='Pending' style={{backgroundColor:'red'}}></div>
    //                                                     // <span className='badge text-bg-danger'>Pending</span>
    //                                                 )
    //                                             }
    //                                             {/* {task.status==='completed'?<span className='badge text-bg-success'>Completed</span>:<span className='badge text-bg-danger'>Pending</span>} */}
    //                                         </td>
    //                                         <td>
    //                                             {task.task}
    //                                         </td>
    //                                         <td style={{textAlign:'center'}}>
    //                                             {formatDate(task.deadline)}
    //                                         </td>

    //                                     </tr>
    //                                 // </tbody>
    //                             ))}
    //                         </table>
    //                         <div className="table-container-phases" style={{display:'none'}}>
    //                             <table className='table-phases'>
    //                                 <tr className='tr_header_carousel'>
    //                                     <th className='th-phases' colSpan={2}>Tasks</th>
    //                                     <th className='th-phases'>Due Date</th>
    //                                 </tr>
    //                                 {props.task && props.task.map((task,index)=>(
    //                                     <tr className='data_tr' key={index} title={task.status}>
    //                                         <td className='td-phases' style={{textAlign:'center',width:'10px'}}>
    //                                             {task.status === 'completed' ? 
    //                                                 (
    //                                                     <div className='status-badge' title='Completed' style={{backgroundColor:'#60e160'}}></div>
    //                                                 ) : task.status === 'in-progress' ? (
    //                                                     <div className='status-badge' title='In Progress' style={{backgroundColor:'orange'}}></div>
    //                                                 ) : (
    //                                                     <div className='status-badge' title='Pending' style={{backgroundColor:'red'}}></div>
    //                                                 )
    //                                             }
    //                                         </td>
    //                                         <td className='td-phases'>
    //                                             {task.task}
    //                                         </td>
    //                                         <td className='td-phases' style={{textAlign:'center'}}>
    //                                             {formatDate(task.deadline)}
    //                                         </td>
    //                                     </tr>
    //                                 ))}
    //                             </table>
    //                         </div>

    //                     </div>
    //                 </div>
    //             </div>


    //         </>
    //     );
    // }


    // function SampleNextArrow(props) {
    //     const { className, style, onClick } = props;
    //     return (
    //       <div
    //         className={className}
    //         style={{ ...style, display: "block"}}
    //         onClick={onClick}
    //       > <svg height="25" viewBox="0 0 24 24" width="25" xmlns="http://www.w3.org/2000/svg" id="fi_2722985">
    //             <g id="_15" data-name="15"><path d="m9 19a1 1 0 0 1 -.71-1.71l5.3-5.29-5.3-5.29a1 1 0 0 1 1.42-1.42l6 6a1 1 0 0 1 0 1.41l-6 6a1 1 0 0 1 -.71.3z"></path></g>
    //          </svg>
    //      </div>
    //     );
    // }
      
    // function SamplePrevArrow(props) {
    //     const { className, style, onClick } = props;
    //     return (
    //       <div
    //         className={className}
    //         style={{ ...style, display: "block" }}
    //         onClick={onClick}
    //       >
    //         <svg height="25" viewBox="0 0 24 24" width="25" xmlns="http://www.w3.org/2000/svg" id="fi_2722991">
    //           <g id="_17" data-name="17"><path d="m15 19a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 0-1.41l6-6a1 1 0 0 1 1.41 1.41l-5.29 5.29 5.29 5.29a1 1 0 0 1 -.7 1.71z"></path></g>
    //        </svg>
    //     </div>
    //     );
    // }




    const settings = {
        // dots: true,
        // infinite: false,
        // speed: 500,
        // slidesToShow: 3,
        // slidesToScroll: 1,
        // autoplay: false,  
        // autoplaySpeed: 3000, 
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />, 

        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        afterChange: (current) => handleAfterChange(current),
        arrows: false,
        responsive: [
            {
                breakpoint: 1200, // For tablets and small screens
                settings: {
                    slidesToShow: 2, // Corrected to 1
                    slidesToScroll: 1, // Corrected to 1
                },
            },
            {
                breakpoint: 991, // For tablets and small screens
                settings: {
                    slidesToShow: 1, // Corrected to 1
                    slidesToScroll: 1, // Corrected to 1
                },
            },
            {
                breakpoint: 480, // For phones
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        // responsive: [
        //     {
        //       breakpoint: 768, // Adjust this breakpoint as needed
        //       settings: {
        //         slidesToShow: 0.9,
        //         slidesToScroll: 0.9,
        //       },
        //     },
        //   ],
    };


    const handleAfterChange = (current) => {
        const totalSlides = sliderRef.current.innerSlider.state.slideCount;
        const slidesToShow = sliderRef.current.innerSlider.props.slidesToShow;
    
        setPrevDisabled(current === 0);
        setNextDisabled(current >= totalSlides - slidesToShow);
      };
      


      const goToPrevSlide = () => {
        sliderRef.current.slickPrev();
      };

      const goToNextSlide = () => {
        sliderRef.current.slickNext();
      };


      

  return (
    
    <>
        {/* <div className="container pt-4" style={{position:'relative'}}>
            <NewCarousel/>
        </div> */}

        <div className="container" style={{position:'relative'}}>


            <div style={{display:'flex',width:'100%',justifyContent:'flex-end',marginBottom:10,zIndex:97,position:'relative'}}>
                <span style={{display:'flex !important'}} className={`slick-arrow slick-prev-button prev-btn ${prevDisabled ? 'slick-disabled' : ''}`}
                    onClick={goToPrevSlide}
                    disabled={prevDisabled}></span>
                <span style={{display:'flex !important'}} className={`slick-arrow slick-next-button next-btn ${nextDisabled ? 'slick-disabled' : ''}`}
                    onClick={goToNextSlide}
                    disabled={nextDisabled}></span>
            </div>

            <div className="slider-wrapper">
                {Object.keys(phases).length === 0 ? (
                    <p>Loading...</p>
                ) : (
                    <>
                    <Slider ref={sliderRef} {...settings}>
                        {Object.entries(phases)
                        .sort((b, a) => b[0].localeCompare(a[0])) // Sort in descending order
                        .map(([phaseKey, phaseValue]) => (             
                            <Phases key={phaseKey}  phaseTitle={phaseKey} title={phaseValue.title} description={phaseValue.description} task={phaseValue.task} />        
                        ))}

                    </Slider>
                    </>
                )}
            </div>
        </div>
    </>
  )



//   function NewCarousel(){
    
//     const sliderRefV2 = useRef(null);

//     const scrollNext = () => {
//         if (sliderRefV2.current) {
//             sliderRefV2.current.scrollBy({ left: 505, behavior: 'smooth' });
//         }
//     };
  
//     const scrollPrev = () => {
//         if (sliderRefV2.current) {
//             sliderRefV2.current.scrollBy({ left: -505, behavior: 'smooth' });
//         }
//     };
//     return(
//         <>
//             <div style={{display:'flex',width:'100%',justifyContent:'flex-end',marginBottom:10,zIndex:97,position:'relative'}}>
//                 <span style={{display:'flex !important'}} className={`slick-arrow slick-prev-button prev-btn`}
//                     onClick={scrollPrev}
//                     disabled={prevDisabled}></span>
//                 <span style={{display:'flex !important'}} className={`slick-arrow slick-next-button next-btn`}
//                     onClick={scrollNext}
//                     disabled={nextDisabled}></span>
//             </div>

//             <div className="new-slider" ref={sliderRefV2}>
//                 {Object.entries(phases)
//                     .sort((b, a) => b[0].localeCompare(a[0])) // Sort in descending order
//                     .map(([phaseKey, phaseValue]) => (             
//                         <PhasesV2 key={phaseKey} className="phases-item"  phaseTitle={phaseKey} title={phaseValue.title} description={phaseValue.description} task={phaseValue.task} />        
//                 ))}
//             </div>
//         </>
//     );
//   }
}

export default Carousel