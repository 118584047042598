import React, { useState,useRef,useEffect } from 'react';
import BackgroundImage from '../../component/BackgroundImage'
import WODOLogo from '../../assets/WODO.png';
import BaseURL from '../../baseURL';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import './style.css';



function LoginPage1() {
    const navigate = useNavigate();

    const [error, setError] = useState('');
    const [data, setData] = useState({
        name: ''
    });
    const inputRef = useRef(null);
    useEffect(() => {
        // Focus the input element when the component mounts
        inputRef.current.focus();
      }, []);

    const [submitBtnStatus, setSubmitBtnStatus] = useState(false);

    const handleInputChange = (e) => {
        const newdata = { ...data };
        newdata[e.target.id] = e.target.value;
        setData(newdata);
        setError('');
    };

    function submitHandle(e) {
        e.preventDefault();
        setSubmitBtnStatus(true);
        axios.post(BaseURL + '/client_name.php', { data })
            .then((res) => {
                if (res.data.status === 'success') {
                    setTimeout(function(){
                        navigate('/' + res.data.name);
                    },800)
                } else {
                    setError('Name was not found');
                    setSubmitBtnStatus(false);

                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <>
            <div className='verticle_center'>
                <div className="box_style p-4">
                    <div className="text-center mb-4">
                        <img src={WODOLogo} width="150" alt="" />
                    </div>
                    <div className="mb-4">
                        <h3 className='maven_pro_semibold'>Sign In</h3>
                        <p className="mb-3 maven_pro_regular">Client Portal</p>
                        <span id="error_message" style={{ color: 'red', fontWeight: 800 }}></span>
                    </div>
                    <form method="post" onSubmit={submitHandle}>
                        <div className={data.name === '' ? "form-group" : 'form-group first field--not-empty'}>
                            <input type="text" ref={inputRef} className='form-control maven_pro_regular' placeholder='Compay Name' name="name" id="name" value={data.name} onChange={(e) => handleInputChange(e)} />
                        </div>
                        {error === '' ? null : <div className="text-danger maven_pro_regular">{error}</div>}
                        <div style={{ width: '100%', textAlign: "center" }} className='mt-4'>
                            {/* <input type="submit" value="Log In" style={{ width: '70%', borderRadius: '60px' }} className="btn py-3 maven_pro_medium btn-black" /> */}
                            <button type='submit' style={{ width: '70%', borderRadius: '60px' }} className="btn py-3 maven_pro_medium btn-black" disabled={submitBtnStatus === true} >
                                {
                                    submitBtnStatus===true ? <span className="spinner-border spinner-border-sm me-2" role="status"></span> :''
                                }
                                Log In
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <BackgroundImage />
        </>
    );
}

export default LoginPage1;
