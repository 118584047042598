import React from 'react'
import EmailIcon from '../../../../assets/MessageIcon.svg'
import WhatsAppIcon from '../../../../assets/WhatsAppIcon.svg'
import CallIcon from '../../../../assets/CallIcon.svg'
import {Link} from 'react-router-dom';
import Ticket from './ClientTicket/Ticket'

function ContactDetails(props){
    return(
        <>
            <table className='tab_tables fs14'>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Details</th>
                        <th style={{textAlign:'center'}}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Email</td>
                        <td>hello@wodo.digital</td>
                        <td style={{textAlign:'center'}}>
                            <Link to="mailto:hello@wodo.digital">
                                <img src={EmailIcon} width={30} alt="" />
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>WhatsApp</td>
                        <td>+91 80889 98516</td>
                        <td style={{textAlign:'center'}}>
                            <Link to="https://wa.me/918088998516" target="_blank">
                                <img src={WhatsAppIcon} width={30} alt="" />
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>Phone</td>
                        <td>+91 80889 98516</td>
                        <td style={{textAlign:'center'}}>
                            <Link to="tel:+918088998516">
                                <img src={CallIcon} width={30} alt="" />
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3} className='text-center'>
                            <button className='btn btn-black' data-bs-toggle="modal" data-bs-target="#raiseTicket">Raise Ticket</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Ticket data={props.data} DataRefresh={props.DataRefresh}/>
        </>
    );
}



function TableSupport(props) {
  return (
    <>
        <ContactDetails data={props.tickets} DataRefresh={props.DataRefresh}/>
    </>
  )
}

export default TableSupport