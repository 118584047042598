import React from 'react'
import DOMPurify from 'dompurify';

function DisplayModel(props) {
    const sanitizedHTML = DOMPurify.sanitize(props.body);
  return (
    <>
        <div className="modal fade" id={props.id} data-bs-backdrop="static" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header" style={{justifyContent: 'center'}}>
                    <h1 className="modal-title fs-5 fs16" id="exampleModalLabel">{props.title}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{position:'absolute',right:'30px'}}></button>
                </div>
                <div className="modal-body fs14" >
                    {/* {props.body} */}

                    <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
                    
                    <br />
                    <div style={{display:'flex',flexDirection:'row-reverse'}}>
                        <button type="button" data-bs-dismiss="modal" className="btn btn-sm btn-yellow">Close</button>
                    </div>
                </div>

                {/* <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div> */}
                </div>
            </div>
        </div>
    </>
  )
}

export default DisplayModel