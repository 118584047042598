import React,{useEffect,useRef} from 'react'
import Cookies from 'js-cookie';
import axios from 'axios';
import BaseURL from '../../../../baseURL';

function Test() {

    const hasFetched = useRef(false);
    
useEffect(() => {
    const cookieExists = Cookies.get('login');
    if(!hasFetched.current){
        hasFetched.current=true;
        if(cookieExists==='abhilash-malkar'){
            axios.post(BaseURL+'/client_data.php',{name:'abhilash-malkar'})
            .then((res)=>{   
                // updateData(res.data.data);
                console.log(res.data.data.requirements);
            })
            .catch((err)=>{console.log(err)})
        }else{
            // navigate('/');
        }
    }
}, [hasFetched])

    
return (
    <>
    Testing....
    </>
  )
}

export default Test