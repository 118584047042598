import React from 'react';

function TableTeam(props) {


    const teams=props.Team;

    function TableDisplay(){
        return(
            <>
                <table className='tab_tables fs14'>
                    <thead>
                        <tr>
                            <th colSpan={2}>Name</th>
                            <th>Designation</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teams && teams.length > 0 ? (
                            teams.map((item, index) => (

                                <tr key={index}>
                                    <td style={{width:10}}>
                                        {/* <span>{item.profile}</span> */}
                                        <img src={'https://office.wodo.digital/assets/media/employee_profiles/'+item.emp_id+'/'+item.profile+''} style={{width:35,borderRadius:100}} alt="" />
                                    </td>
                                    <td>{item.name}</td>
                                    <td>{item.designation}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" style={{textAlign: 'center'}}>No data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </>
        );
    }

    return (
        <>
            <TableDisplay/>
        </>
    )
}

export default TableTeam