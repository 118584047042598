import Background from '../assets/BG.png';

function BackgroundImage() {
    return (
        <>
            <img src={Background} className='backgroundBlock' alt="" />
        </>
    );
}

export default BackgroundImage