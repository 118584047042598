import React from 'react'
import './style.css'

function NotFound() {
  return (
    <div className='content404'>
        <h1>404</h1>
    </div>
  )
}

export default NotFound