import React from 'react'

function Model(){

    function SubmitHandle(){
        alert(
            'yo'
        );
    }

    return(
        <>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        ...
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={SubmitHandle}>Save changes</button>
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
}


function ModelTest() {
  return (
    <>
        <div>ModelTest</div>
        <button className='btn btn-sm btn-secondary' data-bs-toggle="modal" data-bs-target="#exampleModal">Click here</button>
        <Model/>
        <br />
    </>
  )
}

export default ModelTest