import React,{useState} from 'react';
import LinkIcon from '../../../../assets/Link_icon.svg';
import MinutesIcons from '../../../../assets/Minutes_icons.svg';
import DisplayModel from '../../../../component/DisplayModel';
import {Link} from 'react-router-dom';
import './style.css'

function TableMeetings(props) {
  
  const [ModelCommentBody, setModelCommentBody] = useState('')

  const upComingMeeting=props.data_coming_meetings;
  const meetings=props.data_meetings;


  function UpcomingMeetings(){
    return(
      <>
        <h5>Upcoming Meeting</h5>
        <table className='tab_tables fs14'>
          <thead>
          <tr>
            <th className='meetingWidthDate'>Timestamp</th>
            <th>Agenda</th>
            <th>Link</th>
          </tr>
          </thead>
          <tbody>
            {upComingMeeting && upComingMeeting.length > 0 ? (
                upComingMeeting.map((item, index) => (
                    <tr className='data_tr' key={index}>
                        <td><MeetingDetails meetingDate={item.meeting_date} /></td>
                        <td>{item.agenda}</td>
                        <td style={{width:'50px'}}>
                            <Link to={item.link} target='_blank'>
                              <img src={LinkIcon} style={{width:'30px'}} alt="" />
                            </Link>
                        </td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td colSpan="3" style={{textAlign: 'center'}}>No data available</td>
                </tr>
            )}
          </tbody>
        </table>
      </>
    );
  }


  function displayDataInModel(id) {

    console.log(id)

    if(meetings){
      const meeting = meetings.find(req => req.id === id);

      if (meeting) {
          // Assuming meeting has a comments property
          setModelCommentBody(meeting.minutes);
      } else {
          // Handle case where meeting with given id is not found
          console.error("meeting with ID", id, "not found.");
      }
      // setselectedComment('')
  }

    // setModelCommentBody(id);
  }

  const MeetingDetails = ({ meetingDate }) => {
    // Example meetingDate: "17-06-2024 09:00 AM"
    const [date, time,a] = meetingDate.split(' ');
  
    return (
      <div>
          {date}
          <br />
          {time+" "+a}
      </div>
    );
  };

  function Meeting(){
    return(
      <>
        <h5 className='mt-5'>Meeting</h5>
        <table className='tab_tables fs14'>
          <thead>
            <tr>
              <th className='meetingWidthDate'>Timestamp</th>
              <th>Agenda</th>
              <th style={{textAlign:'center'}}>Minutes</th>
              <th>Recording</th>
            </tr>
          </thead>
          <tbody>
          {meetings && meetings.length > 0 ? (
                meetings.map((item, index) => (
                    <tr className='data_tr' key={index}>
                        <td><MeetingDetails meetingDate={item.meeting_date} /></td>
                        <td>{item.agenda}</td>
                        <td style={{textAlign:'center'}}>
                          {/* {item.minutes} */}
                            <img src={MinutesIcons} data-bs-toggle="modal" data-bs-target="#display_model_minutes" onClick={()=>{displayDataInModel(item.id)}} style={{width:'30px',cursor:'pointer'}}  alt="" />
                        </td>
                        <td style={{width:'50px',textAlign:'center'}}>
                            <Link to={item.link} target='_blank'>
                              <img src={LinkIcon} style={{width:'30px'}} alt="" />
                            </Link>
                        </td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td colSpan="4" style={{textAlign: 'center'}}>No data available</td>
                </tr>
            )}
          </tbody>
        </table>
      </>
    );
  }


  
  return (
    <>
      <UpcomingMeetings/>
      <Meeting/>
      <DisplayModel id='display_model_minutes' title='Minutes of the Meeting' body={ModelCommentBody}/>
    </>
  )
}

export default TableMeetings