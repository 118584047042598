import React,{useEffect,useState,useCallback} from 'react'
import { useNavigate,useParams } from 'react-router-dom';
import axios from 'axios'
import './style.css';
import Cookies from 'js-cookie';
import BaseURL from '../../../baseURL';
// import MyDataTable from './Requirements/TableRequirements'
// import TableRequirements from './Requirements/TableRequirements';
import TableMeetings from './Meetings/TableMeetings';
import TableDeliverables from './Deliverables/TableDeliverables';
import TableReports from './Reports/TableReports';
import TableTeam from './Team/TableTeam';
import TableSupport from './Support/TableSupport';
import TableRequirements from './Requirements/TableRequirements';



function Tables() {
    const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'nav-home-tab');
    const [data,setData]=useState({
        requirements:[],
        client_deliverables :[],
        meetings :[],
        coming_meetings :[],
        client_reports :[],
        client_team :[],
        client_tickets:[],
        tasks:[]
    });
    // setSelectedValue(tabId.replace('-tab',''));
    const [selectedValue, setSelectedValue] = useState(localStorage.getItem('activeTab').replace('-tab','') || 'nav-home');
    const navigate = useNavigate();
    const { id } = useParams();
    const cookieExists = Cookies.get('login');



    const DataRefresh = useCallback(async() => {
        if(cookieExists===id){
            await axios.post(BaseURL+'/client_data.php',{name:id,test:2})
            .then((res)=>{   
                updateData(res.data.data);
            })
            .catch((err)=>{console.log(err)})
        }else{
            navigate('/');
        }
    }, [navigate,cookieExists,id])

    

    useEffect(()=>{

        DataRefresh();

    },[id,navigate,cookieExists,DataRefresh]);
    
    const updateData = (data) => {

        const requirements = data.requirements.map((item,index) => ({
            sl: index+1,
            particulars: item.particulars,
            status: item.status,
            comments: item.comments,
            id: item.id
        }));
        const client_deliverables = data.client_deliverables.map((item,index) => ({
            sl: index+1,
            title: item.title,
            delivered_on: item.delivered_on,
            link: item.link,
            approval: item.approval,
            id: item.id
        }));

        const  meetings= data.meetings.map((item,index) => ({
            sl: index+1,
            meeting_date: item.meeting_date,
            agenda: item.agenda,
            link: item.link,
            minutes: item.minutes,
            id:item.id
        }));

        const  coming_meetings= data.coming_meetings.map((item,index) => ({
            sl: index+1,
            meeting_date: item.meeting_date,
            agenda: item.agenda,
            link: item.link,
            id:item.id
        }));

        const  client_team= data.client_team.map((item,index) => ({
            sl: index+1,
            name: item.name,
            designation: item.designation,
            emp_id: item.emp_id,
            profile:item.profile
            // column4: item.email
        }));

        const  client_reports= data.client_reports.map((item,index) => ({
            sl: index+1,
            title: item.title,
            submitted_on: item.submitted_on,
            link: item.link
        }));

        const client_tickets=data.client_tickets.map((item,index) => ({
            sl: index+1,
            id: item.id,
            ticket_id: item.ticket_id,
            created_on: item.created_on,
            subject: item.subject,
            description: item.description,
            status: item.status
        }));

        const tasks=data.client_tasks.map((item,index) => ({
            sl: index+1,
            id:item.id,
            date: item.date,
            task: item.particulars,
            budget:item.budget,
            results:item.results,
            comments:item.comments
        }));

        setData({
            requirements:requirements,
            client_deliverables :client_deliverables,
            meetings :meetings,
            coming_meetings :coming_meetings,
            client_reports :client_reports,
            client_team :client_team,
            client_tickets:client_tickets,
            tasks:tasks,
        })
    }

    useEffect(() => {
        if (activeTab) {
          // Show the corresponding tab content
          const tabContentId = activeTab.replace('-tab', '');
          document.getElementById(tabContentId)?.classList.add('show', 'active');
        } else {
          // Set default active tab
          const defaultTab = document.querySelector('.nav-link:first-of-type');
          defaultTab?.classList.add('active');
          const defaultTabContentId = defaultTab?.getAttribute('id').replace('-tab', '');
          document.getElementById(defaultTabContentId)?.classList.add('show', 'active');
        }
    }, [activeTab]);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        localStorage.setItem('activeTab', tabId);
        // console.log("tabId: "+tabId)
        setSelectedValue(tabId.replace('-tab',''));

    };


    const Tab_handler=(event)=>{
        setActiveTab(event.target.value+'-tab');
        localStorage.setItem('activeTab', event.target.value+'-tab');
        setSelectedValue(event.target.value);
        // console.log("selectedValue: "+selectedValue)
    }


    function TableTabs(){
        return(
            <>
                <div className="pb-5 py-4" >
                    <nav>
                        <div style={{display:'flex',justifyContent:'right'}}>
                            <div className="nav_dropdown">
                                {/*<select className="form-select fs14" onChange={Tab_handler}>
                                     <option value="nav-home" selected={selectedValue === "nav-home"}>Requirements</option>
                                    <option value="nav-meetings" selected={selectedValue === "nav-meetings"}>Meetings</option>
                                    <option value="nav-deliverables" selected={selectedValue === "nav-deliverables"}>Deliverables</option>
                                    <option value="nav-reports" selected={selectedValue === "nav-reports"}>Reports</option>
                                    <option value="nav-team" selected={selectedValue === "nav-team"}>Team</option>
                                    <option value="nav-support" selected={selectedValue === "nav-support"}>Support</option> */}
                                
                                <select value={selectedValue} className="form-select fs14" onChange={Tab_handler}>
                                    <option value="nav-home">Requirements</option>
                                    <option value="nav-meetings" >Meetings</option>
                                    <option value="nav-deliverables" >Deliverables</option>
                                    <option value="nav-reports" >Reports</option>
                                    <option value="nav-team">Team</option>
                                    <option value="nav-support" >Support</option>
                                </select>
                            </div>
                        </div>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className={`fs14 nav-link ${activeTab === 'nav-home-tab' ? 'active ' : ''}`} onClick={() => handleTabClick('nav-home-tab')} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Requirements</button>
                            <button className={`fs14 nav-link ${activeTab === 'nav-meetings-tab' ? 'active' : ''}`} onClick={() => handleTabClick('nav-meetings-tab')} id="nav-meetings-tab" data-bs-toggle="tab" data-bs-target="#nav-meetings" type="button" role="tab" aria-controls="nav-meetings" aria-selected="false">Meetings</button>
                            <button className={`fs14 nav-link ${activeTab === 'nav-deliverables-tab' ? 'active' : ''}`} onClick={() => handleTabClick('nav-deliverables-tab')} id="nav-deliverables-tab" data-bs-toggle="tab" data-bs-target="#nav-deliverables" type="button" role="tab" aria-controls="nav-deliverables" aria-selected="false">Deliverables</button>
                            <button className={`fs14 nav-link ${activeTab === 'nav-reports-tab' ? 'active' : ''}`} onClick={() => handleTabClick('nav-reports-tab')} id="nav-reports-tab" data-bs-toggle="tab" data-bs-target="#nav-reports" type="button" role="tab" aria-controls="nav-reports" aria-selected="false">Reports</button>
                            <button className={`fs14 nav-link ${activeTab === 'nav-team-tab' ? 'active' : ''}`} onClick={() => handleTabClick('nav-team-tab')}  id="nav-team-tab" data-bs-toggle="tab" data-bs-target="#nav-team" type="button" role="tab" aria-controls="nav-team" aria-selected="false">Team</button>
                            <button className={`fs14 nav-link ${activeTab === 'nav-support-tab' ? 'active' : ''}`} onClick={() => handleTabClick('nav-support-tab')} id="nav-support-tab" data-bs-toggle="tab" data-bs-target="#nav-support" type="button" role="tab" aria-controls="nav-support" aria-selected="false">Support</button>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div className={`tab-pane fade py-2 ${activeTab === 'nav-home-tab' ? 'active show' : ''}`} role="tabpanel" aria-labelledby="nav-home-tab"  tabIndex="0">
                            <div className="my-3 w-100">
                                 <TableRequirements data={data.requirements} DataRefresh={DataRefresh}/>
                            </div>          
                        </div>
                        <div  className={`tab-pane fade  ${activeTab === 'nav-meetings-tab' ? 'active show' : ''}`}  role="tabpanel" aria-labelledby="nav-meetings-tab"  tabIndex="0">
                            <div className="my-3 w-100">
                                <TableMeetings data_coming_meetings={data.coming_meetings} data_meetings={data.meetings}  DataRefresh={DataRefresh}/>
                            </div>
                        </div>
                        <div className={`tab-pane fade  ${activeTab === 'nav-deliverables-tab' ? 'active show' : ''}`}  role="tabpanel" aria-labelledby="nav-deliverables-tab"  tabIndex="0">
                            <div className="my-3 w-100">
                                <TableDeliverables deliverables={data.client_deliverables}  DataRefresh={DataRefresh}/>
                            </div>       
                        </div>
                        <div className={`tab-pane fade ${activeTab === 'nav-reports-tab' ? 'active show' : ''}`}   role="tabpanel" aria-labelledby="nav-reports-tab"  tabIndex="0">
                            <div className="my-3 w-100">
                                <TableReports Reports={data.client_reports} Tasks={data.tasks} DataRefresh={DataRefresh}/>
                            </div>  
                        </div>
                        <div className={`tab-pane fade  ${activeTab === 'nav-team-tab' ? 'active show' : ''}`}   role="tabpanel" aria-labelledby="nav-team-tab"  tabIndex="0">
                            <div className="my-3 w-100">
                                <TableTeam Team={data.client_team}/>
                            </div>      
                        </div>
                        <div className={`tab-pane fade  ${activeTab === 'nav-support-tab' ? 'active show' : ''}`}   role="tabpanel" aria-labelledby="nav-support-tab"  tabIndex="0">
                            <div className="my-3 w-100">
                                <TableSupport tickets={data.client_tickets}  DataRefresh={DataRefresh}/>
                            </div>       
                        </div>
                    </div>
                </div>
            </>
        );
    }



    return (
        <>
            <div className="container">
                <TableTabs/>
            </div>
        </>
    )
}

export default Tables