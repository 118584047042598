import React,{useState} from 'react';
import axios from 'axios';
import BaseURL from '../../../../baseURL';
import Modal from 'react-bootstrap/Modal';

function TableRequirements(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [selectedComment, setselectedComment] = useState('')
    const [commentid, setcommentid] = useState(null);

    const data1=props.data;

    const limitWords = (text, limit) => {
        const words = text.split(' ');
        return words.length > limit ? words.slice(0, limit).join(' ') + '...' : text;
      };



    const submitCommentMessage = (e) => {
        e.preventDefault();
        const message = document.getElementById('commentMessageBox').value;

        axios.post(BaseURL+'/update_requirement.php',{"id":commentid,"comment":message})
        .then((res)=>{
            if(res.data.status==='success'){ 
                props.DataRefresh();
                setShow(!show);
            }
        })
        .catch((err)=>{
            console.log(err);
        })
    };

    

    function RequirementsData(){
        const handleClick = (id) => {
            setcommentid(id);
            if(data1){
                const requirement = data1.find(req => req.id === id);
                if (requirement) {
                    setselectedComment(requirement.comments);
                    setShow(true);
                } else {
                    console.error("Requirement with ID", id, "not found.");
                }
            }
        };


        return(
            <>
                <table className='tab_tables fs14'>
                    <thead>
                        <tr>
                            <th colSpan="2">Details</th>
                            <th>Comments</th>
                            {/* <th>Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {data1 && data1.length > 0 ? (
                            data1.map((item, index) => (
                                <tr className='data_tr' style={{cursor:'pointer'}} key={index}  onClick={() => handleClick(item.id)}
                                title={
                                    item.status === 'completed' ? 'Completed' : 
                                    item.status === 'pending' ? 'Pending' : 
                                    'Unknown'
                                } 
                                >
                                    <td className='data_td0' style={{textAlign:'center',width:'33px'}}>
                                        {item.status==='completed'? <div className='status-badge' style={{backgroundColor:'#60e160'}}></div> : <div className='status-badge' style={{backgroundColor:'grey'}}></div>}
                                    </td>
                                    <td style={{padding:'0px'}}>{item.particulars}</td>
                                    <td>{item.comments ? limitWords(item.comments, 3) : 'No comments'}</td>
                                    {/* <td className='fixed-col'>
                                        <button className='btn btn-sm btn-yellow' data-bs-toggle="modal" data-bs-target="#commentModel" onClick={() => handleClick(item.id)}>Comment</button>
                                    </td> */}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" style={{textAlign: 'center'}}>No data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </>
        );
    }
     
    return (
        <>
           
            <RequirementsData/>
            <>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className=" fs-5 maven_pro_medium">Comments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={submitCommentMessage}>
                            <div className="form-floating fs14">
                                <textarea className="form-control" placeholder="Leave a comment here" id="commentMessageBox"  value={selectedComment} onChange={(e) => {setselectedComment(e.target.value)}}  style={{height:'100px'}} required></textarea>
                                <label htmlFor="commentMessageBox">Comments</label>
                            </div>
                            <button type="submit" className="btn btn-yellow w-100 maven_pro_semibold mt-3">Update</button>
                        </form>
                    </Modal.Body>
                </Modal>
            </>

        </>
    )
}

export default TableRequirements