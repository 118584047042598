import { 
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'
import LoginPage1 from './app/auth/loginPage1';
import LoginPage2 from './app/auth/loginPage2';
import Index from './app/MainPage';
import Test from './app/MainPage/Tables/Requirements/Test';
import ModelTest from './app/MainPage/Tables/Requirements/ModelTest';
import Logout from './app/auth/logout';
import './css/App.css'
import NotFound from './app/NotFound/NotFound';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<LoginPage1/>}/>
          <Route path='/:id' element={<LoginPage2/>}/>
          <Route path='/app/:id' element={<Index/>}/>
          <Route path='/logout' element={<Logout/>}/>
          <Route path='/testing' element={<Test/>}/>
          <Route path='/model' element={<ModelTest/>}/>
          <Route path='*' element={<NotFound/>}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
