import React,{useEffect, useState,useRef } from 'react'
import axios from 'axios'
import BaseURL from '../../../../../baseURL'
import Cookies from 'js-cookie'
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { SkewLoader } from 'react-spinners';
function Ticket(props){
    const [loading, setLoading] = useState(false);
    const ticketData=props.data;
    const [lgShow, setLgShow] = useState(false);
    const closeBtnRef = useRef(null);
    const fileRef = useRef(null);
    const cookieExists = Cookies.get('clientId');
    const [ticket,setTicket]=useState({
        subject:'',
        description:'',
        attachments:[],
        client_id:cookieExists,
        file:'no'
    });
    // const [image, setImage] = useState(null);
    const [file,setFile]=useState(null);

    const handlerInput=(e)=>{
        const {name ,value}=e.target;
        setTicket((prevData)=>({
            ...prevData,
            [name]:value,
        }));
    }

    const fileHandle=(e)=>{
        // console.log(e.target.files[0]);
        const file = e.target.files[0];
        const validTypes = ['application/pdf','image/jpeg', 'image/png','image/jpg','image/webp'];
        if (validTypes.includes(file.type)) {
            setFile(file);
        }else{
            e.target.value = ''; 
        }
    }

    useEffect(()=>{
        setTicket((prevData) => ({
            ...prevData,
            attachments: file,
        }));
        if(file !=null){
            setTicket((prevData) => ({
                ...prevData,
                file: 'yes'
            }));
        }
    },[file])

    const ticketHandler=(e)=>{
        e.preventDefault();
        // console.log(ticket)
        const formData = new FormData();
        formData.append('subject', ticket.subject);
        formData.append('description', ticket.description);
        formData.append('client_id', ticket.client_id);
        formData.append('file', ticket.file);
        // if (file) {
            formData.append('attachments', file);
        // }

        axios.post(`${BaseURL}/client_raise_ticket.php`,formData ,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res)=>{
            if(res.data.status==='success'){
                // console.log('success');
                props.DataRefresh();
                setTicket({
                    subject:'',
                    description:'',
                    attachments:[],
                    client_id:cookieExists,
                    file:'no'
                });
                // setImage(null);
                setFile(null);
                if (closeBtnRef.current) {
                    closeBtnRef.current.click();
                }
            }
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    const [getData,setGetData]=useState({
        id:'',
        ticket_id:'',
        date:'',
        subject:'',
        description:'',
        assigned:'',
        status:'',
        client_details:''
    });

    const [messages,setMessages]=useState([]);
    const [last_msg,setLastMsg]=useState('0');

    useEffect(() => {
        if (getData.id !== '') { 
            const interval = setInterval(() => {
                getMessages(getData.id,last_msg);
            }, 5000);
        
            // Cleanup function to clear interval on component unmount
            return () => clearInterval(interval);
        }
    }, [getData.id, last_msg]);


    const viewTicket=(id)=>{
        setLoading(true);
       
        axios.post(`${BaseURL}/client_ticket_details.php`,{'id':id})
        .then((res)=>{
            // console.log(res.data.data);
            if(res.data.status==='success'){
                getNameImages(res.data.data.id);
                setGetData({
                    id:res.data.data.id,
                    ticket_id:res.data.data.ticket_id,
                    date:res.data.data.created_on,
                    subject:res.data.data.subject,
                    description:res.data.data.description,
                    assigned:res.data.data.assign_to,
                    status:res.data.data.status,
                    client_details:res.data.data.client_details,
                })
                if(res.data.data.communication !== ""){
                    // console.log('chat exist');
                    getMessages(res.data.data.id,'1');
                }else{
                    // console.log('chat not exist'); 
                    getMessages(res.data.data.id,'0');
                }
                setTimeout(() => { 
                    setLoading(false);
                    setLgShow(true);
                 }, 1200);
                
                
            }
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    const [filesData,setFilesData]=useState([]);
    const uploadHandler=()=>{
        if (fileRef.current) {
            fileRef.current.click();
        }
    }

    function shortenFilename(fileName) {
        const parts = fileName.split('.');
        const name = parts.slice(0, -1).join('.');
        const extension = parts[parts.length - 1];
        
        const shortenedName = name.length > 5 ? name.substring(0, 5) : name;
        
        return `${shortenedName}.${extension}`;
    }

 

    const uploadFile=(e)=>{
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const validTypes = ['application/pdf','image/jpeg', 'image/png','image/jpg','image/webp'];
            if (validTypes.includes(selectedFile.type)) {
                setFilesData((prevFiles) => [...prevFiles, selectedFile]);
            }else{
                e.target.value = ''; 
            }
        }
    }

    const removeFile=(index)=>{
        setFilesData((prevFiles) => {
            const updatedFiles = [...prevFiles];
            updatedFiles.splice(index, 1);
            return updatedFiles;
        });
    }

    const [msg,setMsg]=useState('');
    const [chatNames,setChatNames]=useState();
    useEffect(()=>{
        // console.log('messages 433'+messages);
        if(messages !== ''){
            const keys = Object.keys(messages);
            const lastKey = keys[keys.length - 1];
            // console.log("lastKey "+lastKey);
            setLastMsg(parseInt(lastKey)+1);
        }
    },[messages]);

    useEffect(() => {
        if(lgShow===false){
            // alert(lgShow);
            setFilesData([]);
            setMsg('');
            setMessages([]);
            setLastMsg('0');

            setGetData({
                id:'',
                ticket_id:'',
                date:'',
                subject:'',
                description:'',
                assigned:'',
                status:'',
                client_details:''
            });
        }
    }, [lgShow])
    

    const textHandler=(id)=>(e)=>{
        e.preventDefault();
        if(msg === '' && filesData.length === 0){
           return true;
        }
        // alert(id);
        var file_exits='no';
        if(filesData.length > 0){
            file_exits='yes';
        }
        axios.post(`${BaseURL}/client_message.php`,{
            "id":id,
            "msg":msg,
            "uploaded_files":filesData,
            'files':file_exits,
            'client_id':cookieExists
        },{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res)=>{
            // console.log(res.data.status);
            if(res.data.status==='success'){
                
                if(messages === ''){
                    // setLastMsg('1');
                    getMessages(id,'1');
                    // console.log('messages is empty');
                }else{
                    getMessages(id,last_msg);
                }
                // getMessages(id,last_msg);
                setFilesData([]);
                setMsg('');
            }
        })
        .catch((err)=>{
            console.error(err);
        })

    }

    function getMessages(id,last_message){
        axios.post(`${BaseURL}/get_client_messages.php`,{
            id:id,
            get_messages:"get_messages",
            "last_message":last_message
        }
        , {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((res)=>{
            if(res.data.status==='success'){
              
                const receivedMessages = res.data.messages;
                if (Object.keys(receivedMessages).length > 0) {
                    getNameImages(id);
                    if (Object.keys(messages).length === 0) {
                        setMessages(receivedMessages);
                    } else {
                        const updatedMessages = {...messages, ...receivedMessages};
                        setMessages(updatedMessages);
                    }
                } else {
                    // console.log('No messages received');
                }
                
            }
        })
        .catch((err)=>{
            console.error(err);
        })

    }

    function getNameImages(id){
        axios.post(`${BaseURL}/get_images_data.php`,{
            id:id,
        }
        , {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((res)=>{
            if(res.data.status==='success'){
                // console.log(res.data.data);
                setChatNames(res.data.data);
            }
        })
        .catch((err)=>{
            console.error(err);
        })
    }

    const getUserById = (id) => {
        return chatNames[id] || { name: "You", pic: getData.client_details.url+'/assets/media/onboard-clients/logo/'+getData.client_details.id+'/'+getData.client_details.pic};
    };

    function convertTimestamp(timestamp) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        
        // Parse the timestamp
        const date = new Date(timestamp.replace(' ', 'T'));

        // Get current date
        const currentDate = new Date();

        // Check if the date is today
        const isToday = date.toDateString() === currentDate.toDateString();

        // Check if the date is within the last week
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(currentDate.getDate() - 7);
        const isWithinLastWeek = date > oneWeekAgo;

        // Format hours and minutes
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;

        const strTime = hours + ':' + minutes + ' ' + ampm;

        // Format the date string
        let dateString;
        if (isToday) {
            dateString = "Today";
        } else if (isWithinLastWeek) {
            dateString = days[date.getDay()];
        } else {
            dateString = `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
        }

        return `${strTime}, ${dateString}`;
    }
    const renderMedia = (media,id) => {
        return media.map((item, index) => {
          const fileExtension = item.split('.').pop().toLowerCase();
          if (fileExtension === 'pdf') {
            return (
              <div
                key={index}
                className="d-flex align-items-center gap-3 btn my-2 border rounded-2 w-50"
                onClick={() => window.open(`${getData.client_details.url}/assets/media/ticket-management-chat/${id}/${item}`, '_blank')}
              >
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png" width="10%" alt="PDF Icon" />
                <span className="maven_pro_regular" style={{ fontSize: '13px' }}>{item}</span>
              </div>
            );
          } else {
            return (
              <img
                key={index}
                src={`${getData.client_details.url}/assets/media/ticket-management-chat/${id}/${item}`}
                width="50%"
                alt={`Media ${index + 1}`}
              />
            );
          }
        });
    };

   
    


    const fileIcon=`<svg id="fi_2258853" enableBackground="new 0 0 512 512" height="20" viewBox="0 0 512 512" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m433.798 106.268-96.423-91.222c-10.256-9.703-23.68-15.046-37.798-15.046h-183.577c-30.327 0-55 24.673-55 55v402c0 30.327 24.673 55 55 55h280c30.327 0 55-24.673 55-55v-310.778c0-15.049-6.27-29.612-17.202-39.954zm-29.137 13.732h-74.661c-2.757 0-5-2.243-5-5v-70.364zm-8.661 362h-280c-13.785 0-25-11.215-25-25v-402c0-13.785 11.215-25 25-25h179v85c0 19.299 15.701 35 35 35h91v307c0 13.785-11.215 25-25 25z"></path><path d="m363 200h-220c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path><path d="m363 280h-220c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path><path d="m215.72 360h-72.72c-8.284 0-15 6.716-15 15s6.716 15 15 15h72.72c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path></svg>`;
    const cancelIcon=`<svg fill="none" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg" id="fi_9199686"><path clip-rule="evenodd" d="m5.29303 5.29301c.18753-.18748.44184-.29279.707-.29279s.51947.10531.707.29279l5.29297 5.29299 5.293-5.29299c.0923-.09552.2026-.1717.3246-.22411s.2533-.07999.386-.08115c.1328-.00115.2645.02415.3874.07443s.2345.12454.3284.21843.1682.20554.2185.32844c.0502.1229.0755.25458.0744.38736-.0012.13278-.0288.264-.0812.386s-.1286.23235-.2241.32459l-5.293 5.293 5.293 5.293c.1822.1886.283.4412.2807.7034s-.1074.513-.2929.6984c-.1854.1854-.4362.2906-.6984.2929s-.5148-.0985-.7034-.2807l-5.293-5.293-5.29297 5.293c-.1886.1822-.4412.283-.7034.2807s-.51301-.1075-.69842-.2929-.29058-.4362-.29285-.6984c-.00228-.2622.09851-.5148.28067-.7034l5.29297-5.293-5.29297-5.293c-.18747-.18752-.29279-.44183-.29279-.707 0-.26516.10532-.51947.29279-.70699z" fill="rgb(0,0,0)" fill-rule="evenodd"></path></svg>`;
    return(
        <>
           {loading ? ( 
             <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
              }}>
            <SkewLoader color="#FFC704" loading={loading} size={36} /> </div>) : ''}
            <div className='my-4'>
              
                  <h5 className='mb-4'>Raised Tickets</h5>
                  <table className='tab_tables fs14'>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Ticket ID</th>
                        <th>Subject</th>
                        <th>Description</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                        {ticketData && ticketData.length > 0 ? 
                        ( ticketData.map((item, index) => (
                                <tr className='data_tr' style={{cursor:'pointer'}} key={index} onClick={() => viewTicket(item.id)} 
                                title={
                                    item.status === 'close' ? 'Closed' : 
                                    item.status === 'open' ? 'Opened' : 'Unknown'
                                } 
                                > 
                                    <td>{item.sl}</td>
                                    <td>{item.created_on}</td>
                                    <td>{item.ticket_id}</td>
                                    <td>{item.subject}</td>
                                    <td>{item.description}</td>
                                    <td>{item.status}</td>
                                </tr>
                                
                            ))) : (
                                <tr>
                                    <td colSpan="6" style={{textAlign: 'center'}}>No data available</td>
                                </tr>
                            )}
                    </tbody>
                 </table>
               
               
            </div>

            <div className="modal fade" id='raiseTicket' tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 maven_pro_semibold" id="exampleModalLabel">Raise Ticket</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeBtnRef}></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={ticketHandler}>
                                <div className='mb-3'>
                                    <label className='maven_pro_medium'>Subject</label>
                                    <input type='text' name='subject' value={ticket.subject}  className='form-control maven_pro_regular'  onChange={handlerInput} required/>
                                </div>
                                <div className='mb-3'>
                                    <label className='maven_pro_medium'>Description</label>
                                    <textarea className='form-control maven_pro_regular' name='description' value={ticket.description}  onChange={handlerInput} required></textarea>
                                </div>
                                <div className='mb-3'>
                                    <label className='maven_pro_medium'>Attachments (Optional)</label>
                                    <input type='file' className='form-control maven_pro_regular' name='description' onChange={(e)=>fileHandle(e)} title='Please select a valid file (pdf or jpeg or png or jpg or webp)'/>
                                    {/* {image && <img src={image}  alt="Uploaded Preview" className='img img-fluid mt-4'/>} */}
                                </div>
                                <div className='mt-5'>
                                    <button type="submit" className="btn btn-black maven_pro_medium w-100" >Raise</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                   <div className='maven_pro_semibold'>Ticket Id :  {getData.ticket_id}</div>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        <div className='w-50'>
                            {/* <div className='mb-3'>
                                Ticket Id : 945895t489
                            </div>
                            <div className='mb-3'>
                                Raised Date : 09/01/2020
                            </div> */}
                            {/* <div className='row mb-3'>
                                <div className='col-4 maven_pro_semibold'>Ticket Id : </div>
                                <div className='col maven_pro_regular'> {getData.ticket_id}</div>
                            </div> */}
                            <div className='row mb-3'>
                                <div className='col-4 maven_pro_semibold'>Raised Date : </div>
                                <div className='col maven_pro_regular'> {getData.date}</div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-4 maven_pro_semibold'>Subject : </div>
                                <div className='col maven_pro_regular'> {getData.subject}</div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-4 maven_pro_semibold'>Description :</div>
                                <div className='col maven_pro_regular'>{getData.description}</div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-4 maven_pro_semibold'>Assigned To : </div>
                                <div className='col maven_pro_regular'>
                                    {getData.assigned !== 'Not Assigned' ? (<div className="d-flex align-items-center">
                                        <img src={getData.assigned.url+'/assets/media/employee_profiles/'+getData.assigned.id+'/'+getData.assigned.pic} className="avatar sm rounded-pill me-3 flex-shrink-0" alt="Customer" /> 
                                        <div>
                                            <div className="maven_pro_regular">{getData.assigned.name}</div>
                                        </div>
                                    </div>) :(
                                        <div className="d-flex align-items-center">
                                        <div>
                                            <div className="maven_pro_regular">Not Assigned</div>
                                        </div>
                                    </div>
                                    )}
                                    
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-4 maven_pro_semibold'>Status : </div>
                                <div className='col maven_pro_regular'>{getData.status}</div>
                            </div>
                        </div>
                        <div>
                            
                            <div className='chat-box'>
                                {getData.status !=='close' ? (<div className='send_message'>
                                    <div className='input-wrapper w-100'>
                                        <form onSubmit={textHandler(getData.id)}>
                                            <img src={getData.client_details.url+'/assets/media/onboard-clients/logo/'+getData.client_details.id+'/'+getData.client_details.pic} className="avatar sm rounded-pill me-3 flex-shrink-0" alt="Customer" /> 
                                            <input className=' py-3 px-4' placeholder='Add a comment' name="msg" value={msg} onChange={(e)=>{setMsg(e.target.value)}}/>
                                            <Dropdown id='btn_2'>
                                            { filesData.length > 0 ? (<span className='custom_badge'>{filesData.length}</span>) : ''}
                                                <Dropdown.Toggle variant="" id="dropdown-basic" title='Please select a valid file (pdf or jpeg or png or jpg or webp)'>
                                                <svg enableBackground="new 0 0 32 32" height="20" viewBox="0 0 32 32" width="20" xmlns="http://www.w3.org/2000/svg" id="fi_3156876"><g id="_x38_2_attachment"><path d="m30 9c0 2.137-.832 4.146-2.343 5.657l-14.41 14.581c-1.172 1.172-2.711 1.758-4.249 1.758-1.536 0-3.072-.585-4.241-1.754-2.339-2.34-2.339-6.146 0-8.485l10.41-10.351c1.563-1.553 4.047-1.604 5.54-.113.729.729 1.113 1.713 1.082 2.772-.031 1.036-.458 2.018-1.203 2.763l-9.879 9.879c-.391.391-1.023.391-1.414 0s-.391-1.023 0-1.414l9.879-9.879c.383-.383.603-.883.618-1.408.015-.502-.162-.964-.497-1.299-.715-.712-1.933-.661-2.715.117l-10.409 10.349c-1.557 1.558-1.557 4.096.002 5.655 1.56 1.559 4.097 1.559 5.657 0l14.41-14.581c1.138-1.138 1.762-2.644 1.762-4.247s-.624-3.109-1.757-4.242c-1.134-1.134-2.64-1.758-4.243-1.758s-3.109.624-4.243 1.758l-14.052 13.952c-.393.389-1.025.387-1.414-.006-.389-.392-.387-1.024.005-1.414l14.05-13.949c1.508-1.509 3.517-2.341 5.654-2.341s4.146.832 5.657 2.344c1.511 1.51 2.343 3.519 2.343 5.656z"></path></g></svg>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    { filesData.length > 0 ? (
                                                        filesData.map((item, index) => (
                                                            <li key={index} className='dropdown-item maven_pro_regular'><span className='me-3' dangerouslySetInnerHTML={{ __html: fileIcon }}></span>{shortenFilename(item.name)} <span  dangerouslySetInnerHTML={{ __html: cancelIcon }} onClick={()=>removeFile(index)}></span></li>
                                                        ))
                                                    ) : ''}
                                                
                                                    <li className='dropdown-item maven_pro_regular' onClick={uploadHandler} title='Please select a valid file (pdf or jpeg or png or jpg or webp)'>
                                                    <input type='file' className='d-none' ref={fileRef} onChange={(e)=> uploadFile(e)}/>
                                                        <span className='me-3'>
                                                        <svg height="20" viewBox="0 0 64 64" width="20" xmlns="http://www.w3.org/2000/svg" id="fi_3185902"><g id="Layer_51" data-name="Layer 51"><path d="m57.47 38.6a2 2 0 0 0 -2 2v6.83a6.07 6.07 0 0 1 -6.07 6.07h-34.8a6.07 6.07 0 0 1 -6.07-6.07v-6.83a2 2 0 1 0 -4 0v6.83a10.08 10.08 0 0 0 10.07 10.07h34.8a10.08 10.08 0 0 0 10.07-10.07v-6.83a2 2 0 0 0 -2-2z"></path><path d="m22 21.3 8-8v29.7a2 2 0 0 0 4 0v-29.67l8 8a2 2 0 0 0 2.83-2.83l-11.41-11.41a2.16 2.16 0 0 0 -1-.55 2.06 2.06 0 0 0 -1.81.55l-11.41 11.38a2 2 0 0 0 2.8 2.83z"></path></g></svg>
                                                        </span>
                                                        Upload File
                                                    </li>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <button >
                                            <svg fill="none" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg" id="fi_10322482"><path d="m22.1012 10.5616-19.34831-9.43824c-.1664-.08117-.34912-.12336-.53427-.12336-.67302 0-1.21862.5456-1.21862 1.21862v.03517c0 .16352.02005.32643.05971.48507l1.85597 7.42384c.05069.2028.22214.3526.42986.3757l8.15756.9064c.2829.0314.4969.2705.4969.5552s-.214.5238-.4969.5552l-8.15756.9064c-.20772.0231-.37917.1729-.42986.3757l-1.85597 7.4238c-.03966.1587-.05971.3216-.05971.4851v.0352c0 .673.5456 1.2186 1.21862 1.2186.18515 0 .36787-.0422.53427-.1234l19.34831-9.4382c.5499-.2682.8988-.8265.8988-1.4384s-.3489-1.1702-.8988-1.4384z" fill="rgb(0,0,0)"></path></svg>
                                            </button>
                                        </form>
                                    </div>
                                </div>) : null}
                                {Object.values(messages).sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).map((msg, index) => {
                                    const user = getUserById(msg.sender);

                                    return (
                                        <div className='d-flex my-5' key={index}>
                                            <div>
                                                {/* <img src={user.pic} alt={user.name} style={{ width: '50px', height: '50px', borderRadius: '50%' }} /> */}
                                                <img src={user.pic} className="avatar sm rounded-pill me-3 flex-shrink-0" alt={user.name} /> 
                                            </div>
                                            <div>
                                                <div className='chat-details'><span className='chat-name me-4'>{user.name}</span> <span>{convertTimestamp(msg.timestamp)}</span></div>
                                                <div>
                                                    {msg.media && msg.media.length > 0 && <div>{renderMedia(msg.media,getData.id)}</div>}
                                                </div>
                                                <div className='chat-message '>{msg.message}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                                 <div className='d-flex my-5'>
                                    <div>
                                        <img src={getData.client_details.url+'/assets/media/onboard-clients/logo/'+getData.client_details.id+'/'+getData.client_details.pic} className="avatar sm rounded-pill me-3 flex-shrink-0" alt={getData.client_details.pic} /> 
                                    </div>
                                    <div>
                                        <div className='chat-details'><span className='chat-name me-4'>You</span> <span></span></div>
                                        <div className='chat-message '>{getData.description}</div>
                                    </div>
                                 </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
          
        </>
    )
}

export default Ticket