import React from 'react';
import axios from 'axios';
import BaseURL from '../../../../baseURL';
import LinkIcon from '../../../../assets/Link_icon.svg';
import {Link} from 'react-router-dom';

function TableDeliverables(props) {
    const deliverables=props.deliverables;

    function TableDisplay(){

        const handleApprovalChange = (event, id) => {
            const selectedValue = event.target.value;
    
            axios.post(`${BaseURL}/update_requirement.php`, {
                id: id,
                selectedValue: selectedValue
            })
            .then(response => {
                console.log('Approval status updated successfully:', response.data);
                // setApprovalStatus([response.data,id])
                props.DataRefresh();
            })
            .catch(error => {
                console.error('There was an error updating the approval status:', error);
            });
        };


        return(
            <>
                <table className='tab_tables fs14'>
                    <thead>
                    <tr>
                        <th colSpan={2}>Title</th>
                        <th>Delivered on</th>
                        <th>Link</th>
                        <th>Approval</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                    deliverables && deliverables.length > 0 ? (
                    deliverables.map((item, index) => (
                        <tr className='data_tr' key={index} 
                        title={
                            item.approval === 'approved' ? 'Approved' : 
                            item.approval === 'in-review' ? 'In Review' : 
                            item.approval === 'rejected' ? 'Rejected' : 
                            item.approval === 'pending' ? 'Pending' : 
                            'Unknown'
                        } 
                        >
                            <td className='data_td0' style={{textAlign:'center',width:'10px'}}>
                                <div className='status-badge' 
                                    title={
                                        item.approval === 'approved' ? 'Approved' : 
                                        item.approval === 'in-review' ? 'In Review' : 
                                        item.approval === 'rejected' ? 'Rejected' : 
                                        item.approval === 'pending' ? 'Pending' : 
                                        'Unknown'
                                    } 
                                    style={{
                                        backgroundColor: 
                                            item.approval === 'approved' ? '#3ec200' : 
                                            item.approval === 'in-review' ? '#FFC704' : 
                                            item.approval === 'rejected' ? 'red' : 
                                            item.approval === 'pending' ? 'gray' : 
                                            'black'
                                    }}>
                                </div>
                                {/* {item.approval === 'approved' ? 
                                    (
                                        <div className='status-badge' title='Approved' style={{backgroundColor:'#60e160'}}></div>
                                    ) : item.status === 'in-review' ? (
                                        <div className='status-badge' title='In Review' style={{backgroundColor:'orange'}}></div>
                                    )  : item.status === 'rejected' ? (
                                        <div className='status-badge' title='Rejected' style={{backgroundColor:'red'}}></div>
                                    ) : item.status === 'pending' ? (
                                        <div className='status-badge' title='Pending' style={{backgroundColor:'red'}}></div>
                                    )  : (
                                        <div className='status-badge' title='Pending' style={{backgroundColor:'black'}}></div>
                                    )
                                } */}
                                {/* {task.status==='completed'?<span className='badge text-bg-success'>Completed</span>:<span className='badge text-bg-danger'>Pending</span>} */}
                            </td>
                            <td>{item.title}</td>
                            <td style={{width:'150px'}}>{item.delivered_on}</td>
                            <td style={{width:'50px'}}>
                                <Link to={item.link} target='_blank'>
                                    <img src={LinkIcon} style={{width:30}} alt="" />
                                </Link>
                            </td>
                            <td style={{width:'50px'}}>
                                {/* <select name="" id=""  className='option-select' onChange={(event) => handleApprovalChange(event, item.id)}>
                                    <option value="" selected disabled>Select Approval</option>
                                        <option value="pending" selected={item.approval === 'pending'}>Pending</option>
                                        <option value="in-review" selected={item.approval === 'in-review'}>In Review</option>
                                        <option value="rejected" selected={item.approval === 'rejected'}>Rejected</option>
                                        <option value="approved" selected={item.approval === 'approved'}>Approved</option>
                                </select> */}
                                <select name="" id="" defaultValue={item.approval} className='option-select' onChange={(event) => handleApprovalChange(event, item.id)}>
                                    <option value="" disabled>Select Approval</option>
                                        <option value="pending">Pending</option>
                                        <option value="in-review">In Review</option>
                                        <option value="rejected">Rejected</option>
                                        <option value="approved">Approved</option>
                                </select>
                            </td>
                        </tr>
                    ))):(
                        <tr>
                            <td colSpan="5" style={{textAlign: 'center'}}>No data available</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </>
        );
    }

    return (
        <>
            <TableDisplay/>
        </>
    )
}

export default TableDeliverables