import React,{useState,useEffect,useRef} from 'react'
import BackgroundImage from '../../component/BackgroundImage'
import BaseURL from '../../baseURL';
import { useNavigate,useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

function LoginPage2() {

    const inputRef = useRef(null);
    useEffect(() => {
        // Focus the input element when the component mounts
        inputRef.current.focus();
      }, []);

    const [submitBtnStatus, setSubmitBtnStatus] = useState(false);
    const [data,setData]=useState('');
    const navigate = useNavigate();
    const [error,setError]=useState('');
    const { id } = useParams();
    const [logo,setLogo]=useState();

    // if (Cookies.get('login')) {
    //     var loginValue = Cookies.get('login');

    //     navigate('../app/' + loginValue);
    // } else {
    //     navigate('./');
    // }


    const handleInputChange = (e) => {
        setData(e.target.value);
        setError('');
    };

    useEffect(()=>{
        axios.post(BaseURL+'/update_requirement.php',{"client_name":id})
        .then((res)=>{       
            if(res.data.status==='success'){
                // console.log(res.data.data);
                const path="https://office.wodo.digital/assets/media/onboard-clients/logo/"+res.data.data.id+"/"+res.data.data.company_logo;
                setLogo(path);
            }
        })
        .catch((err)=>{
            console.log(err);
        })
    },[id])
    const submitHandle=(e)=>{
        e.preventDefault();
        setSubmitBtnStatus(true);
        axios.post(BaseURL+'/client_pwd.php',{name:id,password:data})
        .then((res)=>{
            if(res.data.status==='success'){
                setTimeout(function(){
                    Cookies.set('clientName', res.data.clientName, { expires: 3 });
                    Cookies.set('clientId', res.data.clientId, { expires: 3 });
                    Cookies.set('login', id, { expires: 3 });
                    localStorage.setItem('activeTab', 'nav-home-tab');
                    navigate('/app/'+id);
                },800);
            }else{
                if(res.data.status==='error'){
                    setSubmitBtnStatus(false);
                    navigate('/') ;
                }else{
                    setError('Password is incorrect');
                    setSubmitBtnStatus(false);
                }  
            }
        })
    }


    return (
        <>
            <div className='verticle_center'>
                <div className="box_style p-4" >   
                    <div className="text-center mb-4">
                        <img src={logo} width="150" alt=""/>
                    </div>
                    <div className="mb-4">
                        <h3 className='maven_pro_semibold'>Sign In</h3>
                        <p className="mb-3 maven_pro_regular">Client Portal</p>
                        <span id="error_message" style={{color: 'red', fontWeight: 800}}></span>
                    </div>
                    <form  method="post" onSubmit={submitHandle}>
                        <div className={data==='' ? "form-group mt-3" :'form-group first field--not-empty mt-3 mb-3'}>
                            {/* <label htmlFor="passcode" className='maven_pro_regular mb-2'>Password</label> */}
                            <input type="password" ref={inputRef} className="form-control maven_pro_regular" name="passcode"  id="passcode" placeholder='Password' value={data} onChange={(e)=>handleInputChange(e)} /> 
                        </div>
                        {error==='' ? null : <div className="text-danger maven_pro_regular">{error}</div>}
                        <div  className='w-100 text-center mt-3'>
                            {/* <input type="submit" value="Log In" style={{width:'70%',borderRadius:'60px'}} className="btn py-3 maven_pro_medium btn-black"/> */}
                            
                            <button type='submit' style={{ width: '70%', borderRadius: '60px' }} className="btn py-3 maven_pro_medium btn-black" disabled={submitBtnStatus === true} >
                                {
                                    submitBtnStatus===true ? <span className="spinner-border spinner-border-sm me-2" role="status"></span> :''
                                }
                                Log In
                            </button>
                        </div>
                    </form> 
                </div>
            </div>
            <BackgroundImage/>
        </>
    )
}

export default LoginPage2